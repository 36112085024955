import React, { useContext, useEffect } from 'react'
import AppContext from '../../context/context'
import {
  CitaPreviaEnum,
  citaPreviaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import {
  resetCitaPrevia,
  verifyPresetCitaPrevia,
} from '../../context/actions/citaPreviaActions'
import { t } from '../../i18n'
import { navigate } from 'gatsby'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'


export default function LoadingLanding() {
  const { dispatch } = useContext(AppContext)
  
  useEffect(() => {
    const query = new URLSearchParams(document.location.search)
    const taller = query.get('t');
    const servicio = query.get('s');
  
    const origin = window.location.pathname
    if (
      !taller || !servicio
    ) {
      dispatch(resetCitaPrevia())
      navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
    } else {
      // this will verify there is such taller which offers such service
      // otherwise, redirections are applied.
      dispatch(
        verifyPresetCitaPrevia(
          taller,
          servicio,
          origin,
        )
      )
    }
  })

  return (
    <div>
      <SpinnerWithText text={t('selector.cargando')} />
    </div>
  )
}
